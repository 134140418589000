import './LandingContactsItem.css'

import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'

class LandingContactsItem extends React.Component {

	constructor(props) {
		super(props)
		this.state = {}
	}
	
	componentDidMount() {
		// ajax
	}

	render() {
		
		return (
			
			<div className={'LandingContactsItem'}>
				
				<div className={`name`}>
					{this.props.name}
				</div>
				
				{(this.props.phone) && (
					<div className={`contact-box phone-box`}>
						<a className={`contact phone`} href={`tel:${this.props.phone}`}>{this.props.phone}</a>
					</div>
				)}
				
				{this.props.phones.map((phone, phoneIndex) => {
					return (
						<div className={`contact-box phone-box`} key={`phone-${phoneIndex}`}>
							<a className={`contact phone`} href={`tel:${phone}`}>{phone}</a>
						</div>
					)
				})}
				
				{(this.props.telegram) && (
					<div className={`contact-box telegram-box`}>
						<a
							className={`contact telegram`} 
							href={`https://t.me/${this.props.telegram}`}
							target={`_blank`}
						>@{this.props.telegram}</a>
					</div>
				)}
				
				{(this.props.email) && (
					<div className={`contact-box email-box`}>
						<a className={`contact email`} href={`mailto:${this.props.email}`}>{this.props.emailName || this.props.email}</a>
					</div>
				)}
				
				{this.props.emails.map((emailData, emailDataIndex) => {
					
					let email = null
					let emailName = null
					
					if (Array.isArray(emailData)) {
						email = emailData[0]
						emailName = emailData[1]
					} else {
						email = emailData
						emailName = emailData
					}
					
					return (
						<div className={`contact-box email-box`} key={`email-${emailDataIndex}`}>
							<a className={`contact email`} href={`mailto:${email}`}>{emailName || email}</a>
						</div>
					)
					
				})}
				
			</div>
			
		)
		
	}
	
}

LandingContactsItem.propTypes = {
	
	name: PropTypes.string,
	
	phone: PropTypes.string,
	phones: PropTypes.array,
	
	telegram: PropTypes.string,
	telegrams: PropTypes.array,
	
	email: PropTypes.string,
	emailName: PropTypes.string,
	
	emails: PropTypes.array,
	
}

LandingContactsItem.defaultProps = {
	phones: [],
	telegrams: [],
	emails: [],
}

export default LandingContactsItem