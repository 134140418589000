import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import Config from '../helpers/Config'

import './TopMenu.css'
import './TopMenu_SM-.css'

// import logo from '../img/logo.png'

class TopMenu extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }
    
    componentDidMount() {
        // ajax
    }

    render() {
		
		let contacts = Config.contacts.filter(x => x.showInTopMenu == 1)
        
        return (
            
            <div className={'TopMenu'}>
                
                <nav className="top-menu navbar-expand-lg navbar">
					
					{/* <a className="navbar-brand" href={'#'}>
						<img className={'logo'} src={logo} />
					</a> */}
					
					<div className={`logos`}>
						
						<a className="navbar-brand logo-link airse-logo-link" href={'https://kpfu.ru/engineer'} target='_blank'>
							<img className={`logo airse-logo`} />
						</a>
						
						<a className="navbar-brand logo-link" href={'https://kpfu.ru'} target='_blank'>
							<img className={`logo kfu-logo`} />
						</a>
						
					</div>
					
					<button 
						type="button" 
						className="navbar-toggler" 
						data-bs-toggle="collapse" 
						data-bs-target="#top-menu-collapse" 
					><span className="navbar-toggler-icon"></span></button>
					
					<div id={'top-menu-collapse'} className="collapse navbar-collapse">
						
						<ul className="navbar-nav nav">
							
							<li className="nav-item">
								<a className="nav-link active" href="#grants">
									{i18next.t("Гранты")}
								</a>
							</li>
							
							<li className="nav-item">
								<a className="nav-link active" href="#audit">
									{i18next.t("IT аудит")}
								</a>
							</li>
							
							<li className="nav-item">
								<a className="nav-link active" href="#dev">
									{i18next.t("Разработка")}
								</a>
							</li>
							
							<li className="nav-item">
								<a className="nav-link active" href="#audit-success">
									{i18next.t("Успехи")}
								</a>
							</li>
							
							<li className="nav-item">
								<a className="nav-link active" href="#contacts">
									{i18next.t("Контакты")}
								</a>
							</li>
							
						</ul>
						
						{/* <div className="navbar-text ms-auto">
							
							<div className="top-menu-contacts">
								
								<div className="email-link-box">
									<a className="email-link" href="tel:+7 952 031-20-37">+7 952 031-20-37</a>
								</div>
								
								<div className="phone-link-box">
									<a className="phone-link" href="tel:+7 996 403-32-32">+7 996 403-32-32</a>
								</div>
								
							</div>
							
						</div> */}
						
						<div className="navbar-text ms-auto">
							
							<div className="top-menu-contacts">
								
								{contacts.map((contact, contactIndex) => {
									
									return (
										
										<div className={`row`} key={`top-menu-contact-row-${contactIndex}`}>
									
											<div className={`col-auto contact-col phone-col`}>
												{(contact.phone) && (
													<a className="contact-link phone-link" href={`tel:${contact.phone}`}>{contact.phone}</a>													
												)}
											</div>
											
											<div className={`col-auto contact-col email-col`}>
												{(contact.email) && (
													<a className="contact-link email-link" href={`mailto:${contact.email}`}>{contact.email}</a>													
												)}
											</div>
											
										</div>
										
									)
									
								})}
								
							</div>
							
						</div>
						
					</div>
						
				</nav>
                
            </div>
            
        )
        
    }
    
}

TopMenu.propTypes = {
    preloader: PropTypes.object,
}

TopMenu.defaultProps = {}

export default TopMenu