import React from 'react'
import PropTypes from 'prop-types'

import './LandingDevSample.css'
// import './LandingDevSample2.css'

class LandingDevSample extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }
    
    componentDidMount() {
        // ajax
    }

    render() {
        
        let style = {}
        
        if (this.props.icon) {
            style.backgroundImage = `url(${this.props.icon})`
        }
        
        let classNameArray = [
            'LandingDevSample',
            'card',
        ]
        
        if (this.props.alias) {
            classNameArray.push(this.props.alias)
        }
        
        let appleAppStoreBtn = this.props.appleAppStoreUrl 
            ? <a href={this.props.appleAppStoreUrl} target={`_blank`}><img className={`apple-appstore-black`}/></a>
            : ''
        
        return (
            <div className={classNameArray.join(' ')} style={style}>
                <div className={`card-body`}>
                    <div className={`title`}>
                        {this.props.title}
                        {(this.props.appleAppStoreUrl) && (
                            <div className={`apple-appstore-btn-box`}>
                                {appleAppStoreBtn}
                            </div>
                        )}
                        {/* <div className={`apple-appstore-btn-box`}>{appleAppStoreBtn}</div> */}
                    </div>
                    <div className={`text`}>
                        {this.props.text}
                    </div>
                </div>
            </div>
        )
        
    }
    
}

LandingDevSample.propTypes = {
    alias: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string,
    appleAppStoreUrl: PropTypes.string,
}

LandingDevSample.defaultProps = {}

export default LandingDevSample