import i18n from "i18next";
import {initReactI18next} from "react-i18next";

const resources = {
	
	'en-GB': {
		translation: {
			
			"Сведения о руководителе": 'Information about the manager',
				'Дмитрий Чикрин': 'Dmitry Chickrin',
				'доктор технических наук': 'Ph.D, Prof.',
				'директор Института Искусственного интеллекта,\nробототехники и системной инженерии КФУ': 'Faculty Dean',
				'заведующий кафедрой робототехники и ИТС': '',
				'лауреат премии Завойского': 'Laureate of National Zavoisky Physical Award',
				'инженер\u00A0года\u00A0РФ': 'Laureate of National «Engineer of the Year» Prize',
				'действительный член Академии навигации\nи управления движением': 'Full Member of Academy of Navigation and Motion control',
				
				'Салиха Сайдашева 12, к.3': 'Saidasheva 12, b.3',
				'Казань, 420129': 'Kazan, 420129',
			
			'Директор Института, руководитель проектных групп,\nсистемный архитектор, евангелист беспилотной техники': 'FACULTY DEAN, IT DIRECTOR AND EXPERT,\nIT AND TELECOMM EXPERT, SYSTEM ARCHITECT',
				"разработка программного / аппаратного обеспечения": 'SOFTWARE / HARDWARE DEVELOPMENT',
				"исследования по профилю": 'SCIENTIFIC RESEARCHES',
				"построение сложных инфраструктурных решений и технических систем": 'SYSTEMS ENGINEERING',
			
			"Основные навыки": 'Skills Summary',
			
			"Производственная и исследовательская карьера": 'Career Progression',
				'НПО «Интеграл Плюс» (Казань): инженер беспроводных систем связи, 2006.': '',
				'ФГУП ФНПЦ им. В.И. Шимко «Радиоэлектроника»: начальник сектора беспроводных систем связи, отдел перспективных разработок, 2006-2008': '',
				'ООО КБ «Навигационные Технологии»: технический директор, первый зам. генерального – 2008-2014.': '',
				'Казанский (Приволжский) Федеральный Университет: руководитель лекционных курсов по направлению «Телекоммуникации», старший преподаватель, 2012-2013.': '',
				'Казанский (Приволжский) Федеральный Университет: доцент, заведующий кафедрой «Автономные Робототехнические Системы», 2013-2014.': '',
				'Казанский (Приволжский) Федеральный Университет: проектный руководитель 5 OpenLab по направлению ИКТ – «Андромеда», «Ариадна», «Радуга», «Крона», «Маховик»': '',
				'Казанский (Приволжский) Федеральный Университет, доцент, зам. директора Высшей Школы ИТИС по науке и хоздоговорной деятельности, 2015.': '',
				'Казанский (Приволжский) Федеральный Университет, доцент каф. Радиофизики Института Физики, зам. заведующего отделением Радиофизики и информационных систем по хоздоговорной деятельности, ведущий научный сотрудник, 2015 – 2019.': '',
				'ПАО КАМАЗ - главный специалист НТЦ ПАО КАМАЗ по беспилотным системам, заместитель руководителя КБ беспилотных автомобилей, 2016 – 2020.': '',
				'Руководитель Центра исследований и разработок ИТС КАМАЗ-КФУ, 2018 – декабрь 2020 г.': '',
				'Заведующий кафедрой киберфизических систем КФУ, 2019 – декабрь 2021 г.': '',
				'Руководитель Центра Цифровых Трансформаций КФУ, 2020 – февраль 2021.': '',
				'Руководитель СП4 КФУ Приоритет-2030 (IT, ИИ и киберфизические технологии), 2021 – наст. время.': '',
				'Директор Института вычислительной математики и информационных систем (ИВМиИТ-ВМК) КФУ, 2020 – август 2023 г.': '',
				'Директор Института Искусственного интеллекта, робототехники и системной инженерии (ИИРСИ) КФУ, август 2023 – наст. время.': '',
				'Советник по IT генерального директора Казанского Вертолетного Завода, 2022 – наст. время.': '',
			
			"Наиболее значимые проекты (с 2008 по 2023)": 'Representative Projects (2008 to 2022)',
				"Руководитель проектов федерального уровня (все были приняты\nЗаказчиком без замечаний и сдвига сроков исполнения)": 'Government-wide projects (all project were performed successfully without deadline shifting)',
				'Разработка комплексной системы автопилотирования и обслуживающей инфраструктуры внутрилогистических и карьерных грузоперевозок – НИОКР «Голем» - заказчик ПАО КАМАЗ': '',
				'Гос. контракт на комплексную НИОКР «Разработка аппаратно-программных средств для создания Федеральной системы мониторинга критически важных и (или) потенциально опасных объектов инфраструктуры Российской Федерации и опасных грузов», шифр «Протокол» - ГИС-ориентированная АИС федерального уровня.': '',
				'Гос. контракт на комплексную НИОКР «Исследование возможностей и путей создания автоматизированных информационных систем по направлениям деятельности Управления по обеспечению деятельности подразделений специального назначения и авиации МВД России», шифр «Обследование УПСНиА» - ГИС-ориентированная АИС федерального уровня.': '',
				'Гос. контракт на разработку, поставку и сопровождение «Аппаратно-программный комплекс досмотрового оборудования «Ирбис-Д».': '',
				'Гос. контракт на разработку, поставку и сопровождение «Аппаратно-программный комплекс планирования и управления при проведении специальных операций и тактических учений «Ирбис-К».': '',
			
			"Наиболее наукоемкие проекты (с 2014 по 2023)": 'Major scientific-based projects (all project were performed successfully with full KPI)',
				'Проект «Андромеда» – Программное обеспечение виртуализации телекоммуникационной инфраструктуры – коллектив 7 человек, в сотрудничестве с ИППИ и ИПУ РАН.': 'OpenLab «Andromeda» - SDI virtualization complex software framework – 7 KFU members, in collaboration with Russian Academy of Sciences',
				'Проект «Радуга» - Мультиспектральный синтез и анализ изображений – коллектив 12 человек.': 'OpenLab «Rainbow»: Image fusion and multispectral imaging – 12 KFU members',
				'Проект «Крона» - Разработка фрактальных антенн и антенных решеток фрактального типа – коллектив 10 человек, в сотрудничестве с Cisco Systems.': 'OpenLab «Crown»: Fractal antennas and antenna arrays – 10 KFU members, in collaboration with Cisco Systems',
				'Проект «Маховик» - кинетические накопители энергии супермаховичного типа – коллектив 7 человек.': 'OpenLab «Flywheel»: High-density kinematic energy storage – 7 KFU members, KFU-only',
				'Проект «Ариадна» - инерциальные навигационные системы для платформ со сложной кинематикой движения – 8 человек, в сотрудничестве с ИППИ РАН и Royal Imperial College.': 'OpenLab «Ariadna»: Navigation systems for autonomous platforms – 8 KFU members, in collaboration with Russian Academy of Sciences and Royal Imperial College',
				'Проект «Муромец» - обеспечение устойчивого двуногого прямохождения робототехнических платформ по сложным поверхностям – коллектив 12 человек.': 'Project «Murometz»: Bipedal locomotion on complex terrains – 12 members, KFU-only',
				'Проект «Альтиус-ФА» - фрактальные антенны и антенные системы для БПЛА – коллектив 10 человек.': 'Project «Altius-FA»: Fractal antennas and antenna arrays for modern UAV, 10 members, KFU-only',
				'Проект «Мотылек» - система периметральной охраны на базе виртуальной антенной решетки из MEMS-акселерометров – коллектив 12 человек.': 'Project «Butterfly»: Perimeter intrusion detection MEMS-accelerometer based system, 12 members',
				'Проект «Феникс» - система периметральной охраны на базе виброчувствительного кабеля с использованием принципов временной рефлектометрии – коллектив 20 человек.': 'Project «Phoenix»: Perimeter intrusion detection system based on vibration-sensing cable, 20 members',
				'Проект «Одиссей» - разработка автоматизированного (уровня ADAS 4+) грузового транспортного средства для решения задач внутризаводской логистики – коллектив 70+ человек.': 'Project «Odyssey» – ADAS 4+ level system for truck vehicle automation, 70+ members',
				'Проект «Самсон» - разработка автоматизированного (уровня ADAS 4+) карьерного самосвала средней грузоподъемности – коллектив 40+ человек.': 'Project «Samson» – ADAS 4+ level system for haul-dump truck (mining) automation, 40+ members',
				'Проект «Спутник» - разработка бортовой информационно-диагностической системы автомобилей КАМАЗ – коллектив 20+ человек.': 'Project «Satellite» - Onboard multimedia and online-diagnosis system for nowaday KAMAZ trucks, 20+ members',
				'Проект «Эрго» - разработка ПО автоматизированного проектирования и изготовления антенных систем для радиостанций систем связи 4G+, 10 человек, в сотрудничестве с компанией Huawei.': 'Project «Ergo» - complex CAD-like automation system for fast developing and manufacturing of antennas systems for 4G+, 5G based stations, 10 members, in collaboration with Huawei',
				'Проект «Лилия» - диктофон для мобильных платформ (iOS, Android) с шумоподавлением, комплексной речевой обработкой и решением задач распознавания речи (дважды выбрана редакцией AppStore, постоянно находится в Top1-Top3 Business русского AppStore). 15 человек.': 'Project «Lily» - top-class voice recorder software for iOS, Android – with unique functionality of noise cancellation, complex speech processing and recordings optimization. Twice choosed by Apple on the main page of Russian AppStore as “Our finding”; consistently stays in the top1-3 of Business AppStore app list. 15 members in the team',
				'Проект «Школа Сов» - универсальное платформенное решение (три приложения) для изучения детьми русского\английского\немецкого языков с уникальным собственным движком распознавания нечеткой (детской, с дефектами) речи, логопедическим функционалом и автоматическим контролем выполнения уроков детьми (приложения регулярно находятся в топах Education и Kids русского AppStore). 25 человек.': 'Project «Lily» - top-class voice recorder software for iOS, Android – with unique functionality of noise cancellation, complex speech processing and recordings optimization. Twice choosed by Apple on the main page of Russian AppStore as “Our finding”; consistently stays in the top1-3 of Business AppStore app list. 15 members in the team',
				'Проект «Четкость» - разработка радиостанций с обеспечением интеллектуального шумоподавления для нужд ТЭК и силовых структур, коллектив 18 человек.': 'Project «Fidelity» - R&D of new generation UHF radiostations with noise cancellation and advanced speech processing, 18 members',
				'Проект «Авиценна» - разработка системы индивидуализированной экспертной диагностики состояния здоровья человека по данным KDL-анализов и автоматизированной обработкой анализов графических типов (рентгенография, УЗИ), коллектив 35 человек.': 'Project «Avicenna» - complex AI-based expert decision making-system in the field of individual health monitoring based on KDL analysis and automatic processing of image-based analysis (radiography-based, echography-based), 35 members',
				'Проект «Зеркало» - комплекс мобильных сервисов и портативных устройств индивидуализированной диагностики и постоянного состояния здоровья сотрудников предприятий в условиях повышенной экологической и-или эпидемиологической опасности, коллектив 30 человек.': 'Project «Mirror» - stack of mobile corporative platform services and smart IoT devices (iOS and Android compatible) of individual and group health monitoring for the cases of biological or ecological awareness, 30 members',
				'Проект «Studerus» - проект корректировки произношения студентов-иностранцев, коллектив 18 человек.': 'Project «Studerus» - speech and voice correction iOS / Android-based platform for foreign students',
			
			'Ключевые характеристики': 'Key features',
				
			'Ключевые характеристики как руководителя проектов': 'Key features as PM',
				'Создание и руководство сбалансированными командами в различных предметных областях, научных исследованиях и бизнес-инициативах. Как руководитель – сфокусирован на итоговых целях и непрерывном отслеживании прогресса работ для выполнения проектов точно в срок, запланированный бюджет и с требуемыми результатами': 'Creation and led of balanced teams across different broad technical, scientific and business disciplines. Focused teams on business objectives and tracked progress to ensure project milestones were completed on time, on budget and with the desired results.',
				'Обеспечивает минимизацию технических, технологических и организационных рисков, исходя из текущего статуса по проекту и статистического анализа результатов аналогичных проектов в схожей предметной области. Специалист по экстремальному планированию и сопровождению процессов исполнения работ в условиях крайне сжатых сроков и изменяющихся требований заказчика.': 'Mitigated risk factors through careful analysis of current tech. progress and previous statistical data. Anticipated and managed change effectively in rapidly evolving requirements and extremely tight time ranges.',
				'Технический специалист, обдающий достаточной квалификацией для выбора используемых инструментов и подходов по каждой задаче; всегда сам принимает участие (либо составляет) технические требования и техническое задания по проводящимся проектам. Поддерживает баланс между традиционным «Водопад» и экстремальным «Эджайл» проектированием в зависимости от внешних требований и требований к процессу выполнения проекта.': 'Defined processes and tools best suited to each project. Moved between agile and waterfall approaches depending on project specifics and client goals, creating detailed project road maps, plans, schedules and work breakdown structures.',
			
			'Ключевые характеристики как исследователя и инженера по предметным областям': 'Key features as researcher',
				'Существенные темпы исследований в предметных областях: более 80 публикаций, патентов и РИД за последние 15 лет; 4 авторских курса по системному анализу и телекоммуникационным системам, ряд книг и пособий.': '',
				'Широкий перечень профессиональных компетенций: сверхбыстрое чтение на русском и английском языках (от 2000 до 10000 слов в минуту, в зависимости от типа текста); опыт программирования на MATLAB, VHDL, Delphi, C++, Assembler, MathCAD.': '',
			
			'Ключевые характеристики как члена общества': 'Key features as society member',
				'36 лет, 15 лет женат, имеет дочь, примерный семьянин. Больше всего ценит стабильность и уверенность в будущем для себя, своей семьи и своих коллег.': '36 years, married for 15 years, one daughter. Loyal to his family, friends and colleagues, creative.',
			
			"Подробнее...": 'Show details...',
			"Свернуть": 'Roll up',
			
			
		},
	},
	
}

console.log(`navigator.language [` + typeof navigator.language + `] = %o`, navigator.language)

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		
		resources,
		
		lng: 'ru-RU',
		// lng: 'en-US',
		// lng: 'en-GB',
		
		// lng: navigator.language,
		
		// fallbackLng: "en-GB",
		
		keySeparator: false, // we do not use keys in form messages.welcome
		
		interpolation: {
			escapeValue: false, // react already safes from xss
		}
		
	})

export default i18n